import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import authReducer from "./store/reducers/auth";
import familyReducer from './store/reducers/family';
import mapReducer from "./store/reducers/map";
//import addOnsReducer from './store/reducers/addOns';
import posReducer from "./store/reducers/pos";
import productReducer from "./store/reducers/products";
import eventReducer from "./store/reducers/events";
import couponReducer from "./store/reducers/coupons";
import calendarReducer from "./store/reducers/calendar";
import eventWizardReducer from "./store/reducers/eventWizard";
import serviceReducer from "./store/reducers/service";
import serviceBookingReducer from "./store/reducers/booking";
import splitPaymentsReducer from "./store/reducers/splitPayments";
import patronCartReducer from "./store/reducers/patronCart";
import companyReducer from './store/reducers/company';
import cmsReducer from "./store/reducers/cms";
import cmsElementsReducer from "./store/reducers/cmsElements";
import themeReducer from "./store/reducers/theme";
import permissionsReducer from './store/reducers/permissions';
import notificationsReducer from './store/reducers/notifications';

//normal Enhancers
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//uncomment to allow for trace stack in Chrome dev tools.  It can affect performance but can help in tracking Redux calls down.
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  family: familyReducer,
  map: mapReducer,
  pos: posReducer,
  product: productReducer,
  //addOns: addOnsReducer,
  event: eventReducer,
  coupon: couponReducer,
  calendar: calendarReducer,
  eventwizard: eventWizardReducer,
  service: serviceReducer,
  serviceBooking: serviceBookingReducer,
  splitPayments: splitPaymentsReducer,
  patronCart: patronCartReducer,
  company: companyReducer,
  cms: cmsReducer,
  theme: themeReducer,
  cmsElements: cmsElementsReducer,
  permissions: permissionsReducer,
  notifications: notificationsReducer
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;