import * as actionTypes from '../actions/actionTypes';

const initialState ={
    newUnread: false,
    orders:[],
    others:[]
}

const notificationsReducer=(state=initialState, action)=>{
    switch(action.type){
        case actionTypes.ADJUST_NOTIFICATIONS:
            return{
                ...state,
                [action.notificationType]: action.notifications
            }
        case actionTypes.CLEAR_SINGLE_TYPE:
            return{
                ...state,
                [action.notificationType]: []
            }
        case actionTypes.TOGGLE_UNREAD:
            return{
                ...state,
                newUnread: action.newUnread
            }
        case actionTypes.CLEAR_NOTIFICATIONS:
            return{
                initialState
            }
        default:
            return state;
    }
}

export default notificationsReducer