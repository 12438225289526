import React, { useEffect, useState, useCallback } from 'react';
import { format, startOfDay, endOfDay } from 'date-fns';
import { Modal } from 'react-bootstrap';

import BottomBanner from '../../../../components/common/BottomBanner';
import { SinglePickupOrder } from '../SubComponents/SinglePickupOrder';
import { PickupOrderList } from '../SubComponents/PickupOrderList';
import { getPickupOrders } from '../OnlinePickupUtils';


let intervalMS = 120000; //(2 minute)
const today = new Date();
const orderedIcon = <i className="far fa-circle sub-expired" />
const inProgressIcon = <i className="fad fa-circle-notch sub-suspended" />
const pickupIcon = <i className="fas fa-circle sub-active" />

export const OnlinePickupBanner =()=>{
    
    const [data, setData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showList, setShowList]=useState(null);

    const getData = useCallback(async()=>{
        setData([])
        let filters={
            method: "POST",
            order_status_id: [8, 9, 10],
            date_search_by: "updated_at",
            start_datetime: startOfDay(today),
            end_datetime: endOfDay(today)
        }
        let orders = await getPickupOrders(filters)
        if(orders.data) setData(orders.data.orders)

    },[])

    useEffect(()=>{

        getData();

        const interval = setInterval(()=>{
            getData();
        }, intervalMS)

        return()=>{
            clearInterval(interval)
            setData([])
        }
    },[getData]);

    const handleClick = (e) =>{
        setShowModal(true);
        setShowList(null);
    }

    const handleItemClick = (item)=>{
        setShowModal(true)
        setShowList(item);
    }

    const itemFormat = (item) =>{
        let icon;
        if(item?.order_status_id === 8) icon = orderedIcon;
        else if(item?.order_status_id === 9) icon = inProgressIcon;
        else if(item?.order_status_id === 10) icon = pickupIcon;

        return (
            <span onClick={()=>{handleItemClick(item.id)}} className="cp">
                {icon} #{item.id} ({format(new Date(item?.created_at), 'h:mm a')})
            </span>
        )
    }

    const handleSuccess = (status)=>{
        getData();
        if(status === 11 && showList) setShowModal(false);
    }
    
    return (
            <>
                <BottomBanner 
                    itemFormat={itemFormat}
                    data={data}
                    onDetailClick={handleClick}
                    amountToFilter={4}
                />

                <Modal show={showModal} onHide={()=>setShowModal(false)} size={showList ? "xl" : "lg"}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        {showList ?
                            <SinglePickupOrder item={data[0]} large={true} handleSuccess={handleSuccess} getData={getData} />
                        :
                            <PickupOrderList data={data} large={true} handleSuccess={handleSuccess} loading={!data} />
                        }
                    </Modal.Body>
                </Modal>
            </>
        )
}