import * as actionTypes from './actionTypes';


export const adjustNotifications=(type, notifications)=>{
    return{
        type: actionTypes.ADJUST_NOTIFICATIONS,
        notificationType: type,
        notifications: notifications
    }
}

export const clearSingleType=(type)=>{
    return{
        type: actionTypes.CLEAR_SINGLE_TYPE,
        notificationType: type
    }
}

export const toggleUnread=(newUnread)=>{
    return{
        type: actionTypes.TOGGLE_UNREAD,
        newUnread: newUnread
    }
}

export const clearNotifications=()=>{
    return{
        type: actionTypes.CLEAR_NOTIFICATIONS,
    }
}