import React, { useRef } from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap';

import './PopoverTrigger.scss'

/*
 *  PopoverTrigger - wrapper for react-bootstrap's OverlayTrigger and Popover components
 * https://react-bootstrap.netlify.app/components/overlays/#overlay-trigger-props
 * @param {string} placement - 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start' - The placement of the Overlay in relation to it's target.
 * @param {string} trigger - 'click' | 'hover' | 'focus' - Specify which action or actions trigger Overlay visibility
 * @param {boolean} rootClose - Specify whether the overlay should trigger `onHide` when the user clicks outside the overlay
 * @param {boolean} closeButton - Specify whether the overlay should have a close button in the top right
 * @param {string} popoverContent - The content to be displayed in the popover
 * @param {string} children - The element that will trigger the popover
 */
export const PopoverTrigger = ({
    children,
    popoverContent,
    trigger="click",
    placement="bottom",
    closeButton=false,
    rootClose=true,
    ...props
}) => {

    let popoverRef = useRef(null);

    return (
        <OverlayTrigger
            ref={popoverRef.current}
            placement={placement}
            trigger={trigger}
            rootClose={rootClose}
            overlay={
                <Popover className="sb-popover">
                    <Popover.Content>
                        {closeButton &&
                            <PopoverCloseButton />
                        }
                        {popoverContent}
                    </Popover.Content>
                </Popover>
            }
        >
            {children}
        </OverlayTrigger>
    )
}


const PopoverCloseButton = () => {

    const onClick = () => {
        document.body.click();
    }

    return (
        <button type="button" className="popover-close-button" onClick={onClick}>
            <i className="far fa-times"></i>
        </button>
    )

}
