import * as actions from '../../store/actions';


// /**this will eventually be in redux, which is why most of these were made as thunks before being switched over to local storage to be more reobust with "fake" read statusees */

//when this is moved to redux, most of these will be made into Thunks 
//and then send to Redux instead
//This is required to make local storage behave alittle more like an object we would receive from Redux
export const errorHandlingForLocalNotis=(type, notifications)=>{
    let cleanNotis;
    if(!notifications){
        cleanNotis={};
        cleanNotis[type]=[]
    }else{
        cleanNotis = JSON.parse(notifications)
        if(!cleanNotis[type]) cleanNotis[type] = [];
    } 
    return cleanNotis
}


export const addSingleNotification=(type, newNoti)=>{
    let currentNotifications = localStorage.getItem("notifications")
    let cleanCurrent=errorHandlingForLocalNotis(type, currentNotifications);

    let checkUnique = cleanCurrent[type].filter((each)=>each.id === newNoti.id)
    if(!checkUnique?.length) {
        cleanCurrent[type]=[...cleanCurrent[type], newNoti];
        setUnseenLocalStorage(true);
    }

    localStorage.setItem("notifications", JSON.stringify(cleanCurrent))
}

export const replaceNotificationsByType=(type, newNotis)=>{
    let currentNotifications = localStorage.getItem("notifications");
    let cleanCurrent = errorHandlingForLocalNotis(type, currentNotifications)

    cleanCurrent[type] = newNotis

    setUnseenLocalStorage(true);
    localStorage.setItem("notifications", JSON.stringify(cleanCurrent))
}

export const addNewToExistingNotificationsByType=(type, newNotis)=>{
    let currentNotifications = localStorage.getItem("notifications");
    let cleanCurrent = errorHandlingForLocalNotis(type, currentNotifications);

    let notiOfType = cleanCurrent[type];

    let unique=[];
    let newUnique = false;
    for(let i = 0; i < newNotis?.length; i++){
        let match = notiOfType.filter((noti)=>noti.id === newNotis[i].id)
        if(!match?.length) {
            unique.push(newNotis[i])
            newUnique = true;
        }else if (match) {
            newNotis[i].read = match[0].read
            unique.push(newNotis[i])
        }
    }
    if(newUnique) setUnseenLocalStorage(true);
    cleanCurrent[type] = unique

    localStorage.setItem("notifications", JSON.stringify(cleanCurrent))
}

export const removeSingleNotification=(type, removeId)=>{
    let currentNotifications = localStorage.getItem("notifications");
    let cleanCurrent = errorHandlingForLocalNotis(type, currentNotifications);
    let notiOfType = cleanCurrent[type];

    const index = notiOfType.indexOf(noti => noti.id === removeId)
    notiOfType.splice(index, 1);
    cleanCurrent[type] = notiOfType;

    localStorage.setItem("notifications", JSON.stringify(cleanCurrent))
}

export const removeByKeyValuePair=(type, key, value, remove)=>{
    let currentNotifications = localStorage.getItem("notifications");
    let cleanCurrent = errorHandlingForLocalNotis(type, currentNotifications);
    let notiOfType = cleanCurrent[type];
    

    let keptNotis=[];
    if(remove){
        notiOfType?.forEach((noti)=>{
            if(noti[key] !== value) keptNotis.push(noti)
        })
    }
    else{
        notiOfType?.forEach((noti)=>{
            if(noti[key] === value) keptNotis.push(noti)
        })
    }

    cleanCurrent[type] = keptNotis;

    localStorage.setItem("notifications", JSON.stringify(cleanCurrent))
}

export const removeAllNotificationsByType=(type)=>{
    let currentNotifications = localStorage.getItem("notifications");
    let cleanCurrent = errorHandlingForLocalNotis(type, currentNotifications);

    cleanCurrent[type] = [];

    localStorage.setItem("notifications", JSON.stringify(cleanCurrent))
}

//eventually this will go straight to redux, not local
export const readUnseenLocalStorage=()=>{
    let unseen = localStorage.getItem("unseenNotifications");
    return unseen === "true" ? true : false
}

//eventually this will go straight to redux, not local
export const setUnseenLocalStorage=(bool=true)=>{
    localStorage.setItem("unseenNotifications", bool)
}

export const checkForUnread=(type)=>{
    let currentNotifications = localStorage.getItem("notifications")
    let cleanCurrent=errorHandlingForLocalNotis(type, currentNotifications);

    let notisOfType = cleanCurrent[type];
    let hasUnread = false
    for(let i = 0; i < notisOfType.length; i++){
        if(!notisOfType[i].read) {
            hasUnread = true
            i = notisOfType.length
        }
    }

    return hasUnread;
}

export const markSingleRead=(type, seenId)=>{
    let currentNotifications = localStorage.getItem("notifications")
    let cleanCurrent=errorHandlingForLocalNotis(type, currentNotifications);

    let match = cleanCurrent[type]?.filter((each)=>each.id === seenId)
    if(match?.length){
        match[0].read = true;
    }

    localStorage.setItem("notifications", JSON.stringify(cleanCurrent))
}

export const markAllUnread=(type)=>{
    let currentNotifications = localStorage.getItem("notifications")
    let cleanCurrent=errorHandlingForLocalNotis(type, currentNotifications);
    
    cleanCurrent[type].forEach((noti)=>{
        noti.read = true;
    })

    localStorage.setItem("notifications", JSON.stringify(cleanCurrent))
}