import * as actions from '../store/actions';
import Pos from '../api/Pos';
import Registers from '../api/Registers';
import Users from '../api/Users';
import Services from '../api/Services';
import Groups from '../api/Groups';
import Companies from '../api/Companies';
import Themes from '../api/Themes';
import LoggingAPI from '../api/Error';
import { setItems, cartToItems } from '../containers/POS/Items/Utils';
import { returnFeaturesAvailable } from './features';


//region Register/Cart/Order Thunks
export const getPatronRegister = () => async(getState) => {
    const state = getState();
    return state.pos.register; 
    // try{
    //     // we need a way to specify the main patron register for each company but we can't use is_patron_register because each company will have several
    //     return 0;
    // } catch(err){
    //     console.log(err);
    //     return 0;
    // }
}

const addonsForItem = (new_addons_list, item) => {
    let all_addons = [];
    new_addons_list.forEach( (newAddon, index, arr) => {
        if (item.id === newAddon.parent_id) {
            all_addons.push({
                order_item_id: newAddon.order_item_id===newAddon.id ? null : newAddon.order_item_id,
                id: newAddon.id,
                variant_id: newAddon.variant_id,
                qty: newAddon.qty
            });
            arr.splice(index, 1);   // this has been added to an item, remove it from the array
        }
    });
    return all_addons;
}

/**
 * Updates the current order, local storage, and Redux store with the given items
 * 
 * Must be called as dispatch(addToCart(items))
 * 
 * @param {[{}]} items - in the format returned from Products.get
 * @param {Boolean} overwrite true to overwrite the current order, false (default) to add to the current order
 */
export const addToCart = (items, overwrite = false, register_id, user_id, callback) => async (dispatch, getState) => {

    const state = getState();
    if (!register_id) register_id = state.pos.register;

    if (register_id) {

        if (!state.pos?.[register_id]) {
            dispatch(actions.activeRegister(register_id));
            //await Promise((resolve) => setTimeout(resolve, 1000));
        }

        let originalItems = overwrite ? [] : [...state.pos[register_id].items];
        let order = {};

        // patron cart default to current user; pos default is guest user
        let defaultUser = state.pos[register_id].registerInfo?.register_definition?.is_patron_register ? state.auth.user.profile.id : state.company.config.guest_user_id;
        let userID = state.pos[register_id].user?.id || user_id || defaultUser;
        let orderID = state.pos[register_id]?.orderAll?.id;
        let activeProduct = state.pos[register_id].item;
        let coupons_selected = state.pos[register_id].coupons_selected;
        let order_status = state.pos[register_id].order_status;
        let tip = state.pos[register_id].orderAll?.tip || 0;
        let locationId = state.pos[register_id]?.registerInfo?.location_id

        let addonNewItems = [];
        let regularItems = [];
        //let tempItems = [];

        if (items.length){
            items.forEach(newItem => {
                if (newItem.type===2) {
                    addonNewItems.push(newItem);
                } else {//
                    regularItems.push(newItem);
                }
            });
        }

        // if there's no order and no items, don't create a new one for no reason
        if (!orderID && !state.pos[register_id].user?.id && originalItems.length===0 && items.length===0) {
            return;
        }

        // if there is no existing order, create one
        if (!orderID){
            try {
                // REMOVE LATER: Extra logging to help debug order saving issues
                //console.log("AddtoCart: No orderID THUNKS");
                dispatch(saveLogging(`No Order ID - call Pos.order.save from Items register_id: ${register_id}`));
                const response = await Pos.order.save({register_id: register_id, user_id: userID, order_status_id: 1});
                if (response.data?.[0]) {
                    orderID = response.data[0].id;
                    dispatch(actions.order(response.data[0].id, register_id));
                    dispatch(actions.orderAll(response.data[0], register_id));
                    Pos.local.save(register_id && `POS-${register_id}-order-id`, response.data[0].id);
                } 
            } catch(e) {console.error(e)};
        }

        // just in case the addon belongs to one of the items in the list we're adding - but that shouldn't really happen
        regularItems.forEach(item => {
            let existingAddons = item.addons || [];
            let newAddons = addonsForItem(addonNewItems, item);
            if (newAddons) item.addons = [...existingAddons,  ...newAddons];
        });

        originalItems.forEach( item => {
            if (item.type !== 2){
                let all_addons=[];
                // format existing addons
                item.addons.forEach( addon => {
                    all_addons.push({
                        order_item_id: addon.id || null,
                        id: addon.id,
                        variant_id:addon.variant_id,
                        qty:addon.qty
                    });
                });
                // add new addons
                let newAddons = addonsForItem(addonNewItems, item);
                if (newAddons) all_addons = [...all_addons,  ...newAddons];
                regularItems.push({
                    order_item_id: Object.keys(item).includes("order_item_id") ? item.order_item_id : item.id || null,
                    id: item.id,
                    variant_id: item.variant_id,
                    price_override: item.price_override || item.base_price || null,
                    qty: item.qty || 1,
                    addons: all_addons,
                    event: item.event || null,
                    giftcard: item.giftcard || null,
                    valid_until: item.valid_until || null,
                });
            }
        });

        // remove event items if the user changes, so we dont register events for other people
        if (state && state.pos[register_id].user?.id && state.pos[register_id].orderAll?.user_id !== state.pos[register_id].user?.id){
            regularItems.forEach((item, index, arr) => {
                if (item.event || item.giftcard) {
                    arr.splice(index, 1);
                }
            });
        }

        // update order details
        const response = await Pos.order.update({
            register_id: register_id,
            order_id: orderID,
            items: regularItems,
            user_id: userID,
            order_status_id: order_status,
            applied_coupon_ids: coupons_selected,
            tip: tip,
            location_id: locationId
        });

        if (!response.errors) {
            // update local storage
            if (response.data?.[0]?.items) {
                order = response.data[0];
                let items_set = setItems(response.data[0].items, register_id, response.data[0].id, response.data[0].user_id);
                if (items_set){
                    let last_hash;
                    let last_product_id = 0;
                    let matching_hash = null;
                    let activeProductItemId = activeProduct?.id || null;
                    let tempItems = [];
                    items_set.forEach((item, i) => {
                        tempItems.push(item);
                        if (item.addons){
                            item.addons.forEach(addon=>{
                                tempItems.push(addon);
                            });
                        }
                        last_hash = item.hash;
                        if (item.id > last_product_id) last_product_id = item.id;
                        if (item.id===activeProductItemId) {
                            matching_hash = item.hash;
                        }
                    });
                    dispatch(actions.setCartItems(tempItems, order, register_id));

                    if (activeProduct) {
                        // update the active product's hash and id
                        let activeProductCopy = JSON.parse(JSON.stringify(activeProduct));
                        activeProductCopy.hash = matching_hash || last_hash;
                        if (activeProduct.id===activeProduct.product_id) {
                            activeProductCopy.id = last_product_id;
                        }
                        dispatch(actions.activeItem(activeProductCopy, register_id));
                    }

                    if(items_set.length > state.pos[register_id].items.length) {
                        dispatch(actions.playCartAnimation(register_id));
                    }
                }
                if (callback) callback();
            }
        } else { console.error(response.errors) }
    }
};

/**
 * Updates the current order without changing the order items
 * 
 */
export const updateCart = (register_id, user_id, callback) => async (dispatch, getState) => {
    //console.log("dispatch addToCart Thunks 194 updateCart");
    dispatch(saveLogging(`dispatch addToCart Thunks 194 updateCart`));
    dispatch(addToCart([], false, register_id, user_id, callback));
}

/*
 * Reformats the cart items, then calls the thunk to update the cart
 * This can be called after removing or altering items
 * 
 * Must be called as dispatch(resetCart(items))
 * 
 * @param {[{}]} items - formatted as from redux
 */
export const resetCart = (items, register_id) => async (dispatch, getState) => {
    //console.log("dispatch addToCart Thunks 208 resetCart");
    dispatch(saveLogging(`dispatch addToCart Thunks 208 resetCart`));
    dispatch(addToCart(await cartToItems(items), true, register_id));
};

/**
 * Gets the current order without making any changes - used after an order is complete
 * 
 */
 export const refreshOrder = (register_id, importedOrder=null) => async (dispatch, getState) => {

    const state = getState();
    let orderID;

    if (!register_id) return null;

    if (importedOrder !==null) orderID = importedOrder
    else orderID = state.pos[register_id]?.orderAll?.id;
    let order = {};
    let tempItems = [];
    // get order details
    await Pos.order.get({id: orderID}).then( async response => {
        //console.log("ORDER", response.data)
        if (!response.errors) {
            // update local storage
            await Pos.local.remove(`POS-${register_id}`);
            if (response.data?.items) {
                order = response.data;
                let items_set = setItems(response.data.items, register_id, response.data.id, response.data.user_id, null, state.pos[register_id]);

                if (items_set){
                    items_set.forEach((item, i) => {
                        tempItems.push(item);
                        if (item.addons){
                            item.addons.forEach(addon=>{
                                tempItems.push(addon);
                            });
                        }
                    });
                    if(items_set.length > state.pos[register_id].items?.length) {
                        dispatch(actions.playCartAnimation(register_id));
                    } else if(items_set.length < state.pos[register_id].items?.length) {

                    }
                }
            }
        } else { console.error(response.errors) }
    }).catch(e => console.error(e));

    // update redux store
    if (importedOrder===null){
        dispatch(actions.setCartItems(tempItems, order, register_id));
    } else return order;
}

export const loadOrderIntoRedux = (order, register_id) => async (dispatch, getState) => {

    dispatch(actions.reset(register_id));
    dispatch(actions.order(order.id, register_id));
    dispatch(actions.orderAll(order, register_id));
    dispatch(actions.setCouponsSelected(order?.coupons_applied?.map(coupon => coupon.id), register_id));
    
    let tempItems = [];
    // save all the items to redux
    if (order.items) {
        let items_set = await setItems(order.items, register_id, order.id, order.user_id, null, getState().pos[register_id]);
        if (items_set){
            items_set.forEach((item, i) => {
                tempItems.push(item);
                // if (item.addons){
                //     item.addons.forEach(addon=>{
                //         tempItems.push(addon);
                //     });
                // }
            });
            dispatch(actions.setCartItems(tempItems, order, register_id));
        }
    }
}


/**
 * Gets the current regoster info and saves to redux
 * 
 */
 export const getRegisterInfo = (register_id, is_patron_register=false) => async (dispatch, getState) => {
    // get order details
    await Pos.register.get({
        id: register_id,
        is_patron_register: is_patron_register
    }).then( async response => {
        if (response.data?.length>0) {
            dispatch(actions.setRegisterInfo(response.data[0], register_id));
        }
    }).catch(e => console.error(e));
    if(register_id) dispatch(getRegisterGroup(register_id));
}

export const getRegisterGroup=(register_id)=>async(dispatch, getState)=>{
    await Pos.registerGroups.get({register_id: register_id})
        .then(async response=>{
            if(response.status=== 200 && response?.data?.length >0){
                let registerGroupData = response.data[0].register_group_definition;
                if(registerGroupData){
                    if(typeof(registerGroupData)==="string"){
                        registerGroupData =JSON.parse(response?.data[0]?.register_group_definition)
                    }
                }
                dispatch(actions.setRegisterGroup(response.data[0], register_id))
            }
        }).catch(e=>console.error(e))
}


//#endregion Register/Cart/Order Thunks

//#region Service Booking Thunks

/**
 * Removes appropriate booking event items from the cart and sends api request to cancel the booking event
 * Must be called as dispatch(cancelBooking(items))
 * 
 * @param {[{}]} items - formatted as from redux - all items from the pos
 */
 export const cancelBooking = (register_id) => async (dispatch, getState) => {

    const state = getState();
    const currentBooking = state.serviceBooking.current_booking;
    const items = state.pos[register_id].items;
    
    let numberOfTokens = items.filter(item => item.variant_id === currentBooking.service.default_product_variant_id).length;
    // send event delete request
    Services.cancelBooking({ event_id: currentBooking.event_id }).then(response=>{
        if (response.errors===null) {
            // remove the necessary number of tokens from the pos
            let newItems = [];
            items.map(item => {
                if (item.variant_id===currentBooking.service.default_product_variant_id && numberOfTokens>0) {
                    numberOfTokens--;
                } else {
                    newItems.push(item);
                }
                return item;
            });
            dispatch(resetCart(newItems, register_id));          // reset the cart with the filtered product list
            dispatch(unsetServiceBooking());
        } else {
            console.error(response.errors);
        }
    }).catch(ex=>console.error(ex))
    
};

/**
 * Removes appropriate booking event items from the cart and sends api request to cancel the booking event
 * Must be called as dispatch(unsetServiceBooking())
 * 
 * @param {[{}]} items - formatted as from redux - all items from the pos
 */
 export const unsetServiceBooking = () => async (dispatch, getState) => {
    dispatch(actions.resetServiceBooking());    // remove the current_booking from redux
    localStorage.removeItem("service-booking"); // remove the booking from local storage
};


/**
 * Queries the database for the latest listing of the user's tokens and updates redux auth
 * Must be called as dispatch(reloadAvailableTokens())
 * 
 * @param {[{}]} items - formatted as from redux - all items from the pos
 */
 export const reloadAvailableTokens = (register_id) => async (dispatch, getState) => {

    const state = getState();

    let userID = state.pos[register_id].user.id;

    if (userID) {
        // get all user's tokens
        Users.tokens({ user_id: userID })
        .then(response => {
            if(response.data) {
                dispatch(actions.setPosAvailableTokens(response.data));
            }
        }).catch(e => console.error(e));
    }
};

//#endregion Service Booking Thunks


//#region Family Thunks/Functions
/**
 * Save Family data to redux for use elsewhere
 */
export const setFamily = ()=>async(dispatch, getState)=>{
    const state = getState();
    const userId = state.auth.user.profile.id;
    const familyIds = state.auth.user.profile.family_groups;

    let members=[]
    let familyGroups=[]

    if (familyIds) {
        for(let i=0; i<familyIds?.length; i++){
            //only get families that the member is confirmed in
            try{
                if(familyIds[i].group_member_status_id===2){
                    let response = await Groups.get({id: familyIds[i].id})
                    if(!response.errors){
                        familyGroups.push(response.data[0])
                        response.data[0].group_members.forEach(member=>{
                            if(member.group_member_status_id === 2 && member.user_id !== userId){
                                members.push(member)
                            }
                        })
                    }
                }
                if(i+1===familyIds.length){
                    let idAndName = []
                    members.forEach((member)=>{
                        idAndName.push({
                            user_id: member.user_id, 
                            first_name: member.first_name, 
                            last_name: member.last_name,
                            dob: member.dob
                            //insert profile image here
                        })
                    })
                    let uniqueIdName=makeUnique(idAndName)
                    dispatch(actions.setAllFamilyNoDupes(uniqueIdName))
                    dispatch(actions.setAllFamilyMembers(members))
                    dispatch(actions.setAllFamilyGroups(familyGroups))
                }
            }
            catch(ex){console.error(ex)}
        }
    }
};

const needToGetFamily=(state, familyGroups)=>{
    const familyMembers = state?.family?.all_family_members;
    if(familyGroups?.length > 0 && familyMembers.length === 0) return true;
};
//#endregion Family Thunks

//#region Auth Thunks

/**
 * Queries the database for a user's login status and updates redux auth
 * Must be called as dispatch(attemptAuth(user, password, rememberme))
 * 
 * @param {string} user - username
 * @param {string} password
 * @param {int} rememberme - 0 or 1
 * @param {string} redirect_path 
 */
 export const attemptAuth = (user, password, rememberme=1, redirect_path=null) => async (dispatch, getState) => {

    const state = getState();

    const authData = {
        username: user,
        password: password,
        rememberme: parseInt(rememberme),
    };
    Users.get(authData).then(response => {
        if (response?.error) {
            dispatch(actions.authFail(response.error));
        } else if (response?.data?.token) {
            localStorage.setItem('user',JSON.stringify(response.data));
            dispatch(actions.authSuccess(response.data));
            let getFamily = needToGetFamily(state, response.data?.groups);
            if(getFamily) dispatch(setFamily());
            dispatch(setCompanyDetails());
            if (redirect_path) window.location.href = redirect_path;
        } else {
            dispatch(actions.authFail("Login Error" /*- No Token*/));
        }
    }).catch(error => dispatch(actions.authFail(error)));
};


/**
 * Loads the user's login status and info from localStorage on page refresh, reset Redux
 * Must be called as dispatch(reloadAuth())
 */
 export const reloadAuth = () => async (dispatch, getState) => {
    const state = getState();
    
    let user = localStorage.getItem("user");
    if (user) {
        let parsedUser = JSON.parse(user);
        let authState = { 
            token: parsedUser.token || null,
            user: parsedUser,
            logged_in: true
        };
        dispatch(actions.resetAuth(authState));
        let getFamily = needToGetFamily(state, parsedUser?.groups);
        if(getFamily) dispatch(setFamily());
        dispatch(setCompanyDetails());
    }
};


/**
 * Refreshes the user's data
 * Must be called as dispatch(updateAuthUser())
 */
 export const updateAuthUser = () => async (dispatch, getState) => {
    const state = getState();

    let localUser = JSON.parse(localStorage.getItem("user"));
    let id = state.auth?.user?.id || localUser?.profile?.id;
    
    Users.get({id: id}).then(response => {
        if (response?.data) {
            let responseProfile = response.data[0];
            // also update redux user profile
            let reduxUser = state.auth.user;
            reduxUser.profile = responseProfile;
            // save the new info under the local storage profile
            localUser.profile = response.data[0];
            localStorage.setItem('user',JSON.stringify(localUser));
            dispatch(actions.setAuth({ user: reduxUser }));
            let getFamily = needToGetFamily(state, responseProfile?.groups);
            if(getFamily) dispatch(setFamily());
            dispatch(setCompanyDetails());
        } else {
            console.error("Error getting user info");
        }
    }).catch(error => {console.error(error)});
};


/**
 * Logout the user
 * Must be called as dispatch(logout(history))
 * 
 * @param {[{}]} items - formatted as from redux - all items from the pos
 */
 export const logout = (history, portal = true) => async (dispatch, getState) => {

    //const state = getState();

    localStorage.clear();
    dispatch(actions.logout()); // this doesn't do anything
    dispatch(actions.resetFamily());

    if (portal) history.push("/p/");
    else {
        // get the index page for the company
        const res = await Themes.get({my:1});
        if (res?.data?.[0]?.index_page) {
            history.push(res.data[0].index_page);
        } else history.push("/");
        window.location.reload();
    }
};

//#endregion Auth Thunks

/**
 * Set company details (address, phone number, etc) based on the user.company_id to be used in print settings
 */
export const setCompanyDetails = ()=>async(dispatch, getState)=>{
    const state = getState();
    const companyId = state.auth.user.company_id;
    try{
        let response = await Companies.get({id: companyId})
        if(response.status === 200 && response.data) {
            dispatch(actions.setCompanyDetails(response.data[0]))
        }
        else if(response.errors) dispatch(actions.setCompanyDetails({
            name: "Siteboss"
        }))
    }catch(ex){console.error(ex)};
}

//utlity function for above saving family - will reduce JSON down to string to determine uniqueness and then convert it back
const makeUnique=(array)=>{
    let stringObj=array.map((eachMember)=>JSON.stringify(eachMember));
    let objectSet=new Set(stringObj);
    let uniqueArray = Array.from(objectSet)
    return uniqueArray.map((eachString)=>JSON.parse(eachString))
}

export const saveLogging = (message) => async (dispatch, getState) => {
    LoggingAPI.save({message: message});
}

export const loadCompanyConfig=()=>async(dispatch)=>{
    try{
        let response = await Companies.getConfig({config_type_id: 1, is_active: 1})
        if(response.status === 200 && response?.data?.[0]?.config){
            let filteredConfigs = response.data.sort((a,b)=>a.sort_order - b.sort_order);
            dispatch(actions.setCompanyConfig(filteredConfigs[0].config));
        }else console.error("Error retrieving Company Config")
    }catch(ex){
        console.error(ex)
    }
}

export const loadFeaturesAvailable=()=>async(dispatch, getState)=>{
    const state = getState();
    const companyId = state?.auth?.user?.company_id;
    if(companyId) {
        try{
            let features = await returnFeaturesAvailable(companyId);
            dispatch(actions.setAvailableFeatures(features))
        }catch(ex){
            console.error(ex);
        }
    }
}