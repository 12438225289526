import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import DisplayOrdersTable from '../../../User/Profile/UserOrders/DisplayOrdersTable';
import RefundDisplay from '../../../Pay/Refund/RefundOrderDisplay';
//import Refund from '../../../Pay/Refund';
import Wizard from '../../../Pay/Refund/Wizard';
//import SimpleRefund from '../../../Pay/Refund/SimpleRefund';
import MobileRefundDisplay from '../../../Pay/Refund/RefundOrderDisplay/MobileRefundDisplay';
import MobileDisplayOrdersTable from '../../../User/Profile/UserOrders/DisplayOrdersTable/MobileDisplayOrdersTable';
import { authUserHasModuleAccessMany } from '../../../../utils/auth';
import { loadCompanyConfig } from '../../../../utils/thunks';

import Toast from '../../../../components/Toast';

import APIProducts from '../../../../api/Products';
//import styles from './OrderItems.module.scss';

const REFUND_PERMISSION_MODULE_ID = 178;
const VIEW_ADVANCED_ORDER_DETAILS_MODULE_ID =298;

/** Component that simply displays the orders from a transaction. 
 * @param object currentOrder -the order that is being displayed
 * @param function props.handleCheckbBox  -what happens when an order is checked
 * @requires order_status_id===2(Completed)||order_status_id===5(partially_refunded) for checkboxes to be enabled.  Being 1(Pending), 3(Cancelled), or 4(Refunded) will disbale checkboxes
 */
const OrderItems = ({
    currentOrder=null, 
    parentOrder,
    modifiedRefund, 
    modifiedCompleted, 
    setError, 
    setSuccess, 
    refunded, 
    partialRefund,
    doneStatuses,
    redrawOrder,
    registerId
}) => {

    const mountedRef = useRef(false);
    const containerRef = useRef(null);
    const dispatch = useDispatch();
    const companyConfig = useSelector(state => state.company?.config);

    const [ isMobile, setIsMobile ]=useState(false);
    const [ isAdmin, setIsAdmin ]=useState(false);
    const [ isPatron, setIsPatron ]=useState(false);
    const [ refundSuccess, setRefundSuccess ]=useState(false);
    const [ orderLength, setOrderLength ]=useState(0)
    const [ refundTotal, setRefundTotal ]=useState(0);
    const [ refundItems, setRefundItems ]=useState([]);
    const [ showAdvancedRefund, setShowAdvancedRefund ]=useState(false);
    const [ refundable, setRefundable ]=useState(null);
    const [ tokenDetermined, setTokenDetermined ]=useState(false);
    const [ disableRefunds, setDisableRefunds]=useState(true);
    //const [ simpleRefund, setSimpleRefund ]=useState(false);
    const [ itemsRefundable, setItemsRefundable ]=useState([]);
    const [ refundRegister, setRefundRegister ]=useState(null);

    const statusCheck=useCallback(()=>{
        const refundableArray = [12, 2, 8, 9, 10, 11];
        const refundableStatus = refundableArray.indexOf(currentOrder.order_status_id);
        if(refundableStatus) return true
        else return false
    },[currentOrder])

    const getAllRefundable=useCallback(()=>{
        if(currentOrder){
           let refundableItems=[]
           currentOrder.items.forEach((item)=>{
               if (item.total_refunded_amount === 0 && item.shipping_refunded_amount === 0 && item.product_type_id!==9 && item.product_type_id!==6){
                   refundableItems.push(item);
               }
           })
           if(mountedRef.current) setItemsRefundable(refundableItems);
        }
   },[currentOrder]);

   //Determines if the whole transaction is refundable or not
    const determineRefundability=useCallback(()=>{
        let bool=false;
        const isRefundable = statusCheck();
        if(isRefundable) bool = true;
        if (currentOrder.admin_fee_refunded !== 0) bool = false; //if admin fee has been refunded
        currentOrder.items.forEach((item)=>{ //if any part of an item has been refunded
            if (item.total_refunded_amount !== 0 //already had some for items refunded
                || item.shipping_refunded_amount !==0 //already had shipping refunded
                || item.product_type_id===9  //or is token 
                || item.product_type_id===6) //or is bundle
            bool = false;
        })
        if (currentOrder.split_order_ids?.length > 0) bool = false; //if it's a parent order
        if (currentOrder.items.length === 0 && !currentOrder.parent_id) bool = false; //if there are no items - the exception being if it's a child order
        if (mountedRef.current ===true) setRefundable(bool);
    },[currentOrder, statusCheck]);

    const checkForTokenBundle=useCallback(async(item)=>{
        let isToken=false;
        try{
            let response = await APIProducts.get({id: item.product_id});
            if(!response.errors) {
                let bundle = response.data.products[0].bundled_products;
                let bool = bundle.some((items)=>{
                    if (items.product_type_id===9) return true;
                    return false;
                })
                if(bool===true) isToken=true;
            } else if (response.errors){
                isToken=true; //if for some reason token status cannot be determined, default to unrefundable
            }
        }catch(ex){
            console.error(ex);
        }
        item.disabled=isToken;
        setTokenDetermined(true);
    },[]);

    const addTotalRefund=useCallback((item, checked)=>{

        /*let shipping = 0, tax = 0;
        let value = currentOrder.admin_fee_total; // ??? why is this here?
        if (item.shipping) shipping = item.shipping;
        if (item.tax) tax = item.tax;
        value = +item.price + +shipping + +tax;
        let num = Math.round(value*100)/100;
        setRefundTotal(prev => +prev + checked ? +num : -num);*/

        //we need to remove any admin authorized discounts from the total.  Those didn't have any cash or card value, so refunding them will just lose the company money
        let total = currentOrder.payment_total;
        const managerPayments = currentOrder?.transactions?.filter((transaction)=>transaction?.transaction_payment_method_id === 5 ) || [];
        if(managerPayments?.length > 0){
            managerPayments.forEach((payment)=>{
                total -= payment.amount;
            })
        }

        setRefundTotal(total);

    },[currentOrder]);

    //Disable/enable refund button based on having items selected
    useEffect(()=>{
        const isRefundable = statusCheck();
        if (refundTotal > 0 && isRefundable) setDisableRefunds(false);
        else setDisableRefunds(true);
    }, [refundTotal, currentOrder.order_status_id,statusCheck]);

    //Set mounted on mount and unmount
    useEffect(()=>{
        mountedRef.current =true;

        const checkModulePermissions=async()=>{
            try{
                let response = await authUserHasModuleAccessMany([REFUND_PERMISSION_MODULE_ID, VIEW_ADVANCED_ORDER_DETAILS_MODULE_ID]);
                if (response){
                    setIsAdmin(response[REFUND_PERMISSION_MODULE_ID])
                    setIsPatron(!response[VIEW_ADVANCED_ORDER_DETAILS_MODULE_ID])
                } else { //if there's a problem, assume the user doesn't have access
                    setIsAdmin(false);
                    setIsPatron(true);
                }
            } catch(ex) {
                console.error(ex);
            }
        }

        const getCompanyConfig=async()=>{
            if (companyConfig) setRefundRegister(companyConfig.refund_register);
            else {
                if (currentOrder) setRefundRegister(currentOrder?.register_id);
                dispatch(loadCompanyConfig());
            }
        }

        getCompanyConfig();
        checkModulePermissions();
        if (containerRef.current.offsetWidth < 540) setIsMobile(true);
        else setIsMobile(false);

        return()=>{
            mountedRef.current = false;
        }
    },[companyConfig, dispatch, currentOrder]);

    //Handles changing between refund and order display
    useEffect(()=>{
        if(showAdvancedRefund === false && refundItems.length > 0){
            for(let i = 0; i < refundItems.length; i++){
               const checkbox = document.getElementById(refundItems[i].key)
               checkbox.checked = true;
            }
        }
    }, [showAdvancedRefund,refundItems]);

    //this makes sure we have the order refundability before assigning refundability to items
    useEffect(()=>{
        if(refundable || refundable === false){ //have to specify false here to make sure it isn't null anymore
            const noRefundCriteria=(item, addon=false)=>{
                let disabled = false;
                let refundableStatus = statusCheck();
                if((!refundableStatus) //for orders, complete is 2, partially paid is 12, and there's no transactionId
                        || ((item.total_refund_amount && item?.total_refunded_amount !== 0) //already refunded
                        || (item.shipping_refund_amount && item?.shipping_refunded_amount !==0)  //already refunded
                        || (!addon && item?.product_type_id===9)  //token product
                        || (!addon && item?.product_type_id===6)) //bundle product
                    ){
                        //If the order is any status other than complete, it disables the item.  Item is also disabled if there is any refunded amount in either field.
                        //Also disables items individually based on having a refund amount or being a service token (type 9) and bundles that can contain tokens (6)
                        disabled = true; 
                    }
                    if(item.product_type_id===6) checkForTokenBundle(item);
                    if(refundable === false) disabled = true //if the whole order is not refundable, an item cannot be refundable
                    item.disabled = disabled;

                    if (!disabled) addTotalRefund(item, true);
            }

            currentOrder.items.forEach(item => {
                noRefundCriteria(item);
                if (item?.addons?.length > 0) {
                    item.addons.forEach(addon => {
                        noRefundCriteria(addon, true);
                    });
                }
            })

        } 
    },[refundable, currentOrder, checkForTokenBundle, addTotalRefund]);

    //This determines refundability.  In the future, when partial refunds are available, add other order_status_id (such as refunded), and allow the individual item disabling to handle it.
    useEffect(()=>{

        if(currentOrder){
            determineRefundability();
            getAllRefundable();
            setOrderLength(currentOrder.items.length)
            
        }
    },[currentOrder, getAllRefundable, determineRefundability]);

    
    /**Adds items names/prices for the items to be refunded */
    const recordRefundedItems=(item, checked)=>{
        if(checked === true){
            setRefundItems(refundItems => [ ...refundItems, item])
        }
        if(checked === false){
            for(let i =0; i <refundItems.length; i++){
                if(refundItems[i].name === item.name){
                    refundItems.splice(i, 1)
                    break;
                }
            }
        }
    }
    
    const handleCheckBox=(item, e, i)=>{
        let checked = e.target.checked
        let price = (item.final_price *item.quantity) 
        let key = `cb-items-${item.id}`
        let info = {item_id: item.id, name: item.product_name, price: price.toFixed(2), key: key, shipping: item.shipping, id:item.id, tax: item.tax}
        addTotalRefund(info, checked);
        recordRefundedItems(info, checked);
    }

    const cleanUp=()=>{
        setShowAdvancedRefund(false);
        //setSimpleRefund(false);
        redrawOrder();
    }

    return (
        <div ref={containerRef}>
            {currentOrder&& currentOrder?.payment_method_id!==7 && !currentOrder.parent_id &&
                <>{partialRefund ?
                    <> 
                        <h5>
                            <i className="far fa-tag" />
                            Refunded Items
                        </h5> 
                        {isMobile ?
                            <MobileRefundDisplay currentOrder={modifiedRefund} isPatron={isPatron} />
                        :
                            <RefundDisplay currentOrder={modifiedRefund} isPatron={isPatron} />
                        }
                        <h5>
                            <i className="far fa-tags" />
                            Order Items
                        </h5>
                        {isMobile ? 
                            <MobileDisplayOrdersTable currentOrder={modifiedCompleted} handleCheckBox={handleCheckBox} isAdmin={isAdmin} isPatron={isPatron} />
                        :
                            <DisplayOrdersTable currentOrder={modifiedCompleted} handleCheckBox={handleCheckBox} isAdmin={isAdmin} isPatron={isPatron}/>
                        }
                    </>
                :
                    <>
                        {refunded ?
                            <>
                                <h5>
                                    <i className="far fa-tag" />
                                    Refunded Items
                                </h5> 
                                {isMobile ? 
                                    <MobileRefundDisplay currentOrder={currentOrder} isPatron={isPatron} />
                                    :
                                    <RefundDisplay currentOrder={currentOrder} isPatron={isPatron}/>
                                }
                            </>
                        :
                            <>
                                <h5>
                                    <i className="far fa-tags" />
                                    Order Items
                                </h5> 
                                {isMobile ? 
                                    <MobileDisplayOrdersTable handleCheckBox={handleCheckBox} isAdmin={(currentOrder.split_order_ids && currentOrder.split_order_ids.length > 0) ? false : isAdmin} currentOrder={currentOrder} isPatron={isPatron} />
                                :
                                    <DisplayOrdersTable handleCheckBox={handleCheckBox} isAdmin={(currentOrder.split_order_ids && currentOrder.split_order_ids.length > 0) ? false : isAdmin} currentOrder={currentOrder} isPatron={isPatron} />
                                }
                            </>
                        }
                    </>
                }</>
            }   
            {currentOrder?.payment_method_id===7 && //Token redemptions
                <span className="d-flex justify-content-center mb-5 bold">
                    This transaction was a token redemption.
                </span>
            }
            {currentOrder.parent_id && //child of a split payment
                <>
                    {parentOrder &&
                        <>
                            {isMobile ? 
                                //these are false for isAdmin because isAdmin determines if refunds can be made.  Split payments are by amount and as such, can only use a simple refund
                                <MobileDisplayOrdersTable handleCheckBox={handleCheckBox} isAdmin={false} currentOrder={parentOrder} isPatron={isPatron} />
                            :
                                <DisplayOrdersTable handleCheckBox={handleCheckBox} isAdmin={false} currentOrder={parentOrder} isPatron={isPatron} />
                            }
                        </>
                    }
                </>
            }
            {isPatron ? 
                null : 
                <p className="small my-3">
                    **Please note, Tokens and Token bundles are currently not refundable.**  
                    <br />
                    To start a refund or return, use the "Refund" button.
                </p>
            }
            {currentOrder && isAdmin && 
                /*currentOrder.order_status_id === 2 && currentOrder.payment_method_id !==7 &&*/ !currentOrder.split_order_ids.length &&
                    <>
                        <Button  
                            data-cy="advanced-refund-btn"
                            variant="primary"
                            disabled={!refundable || currentOrder?.split_order_ids?.length > 0 || disableRefunds} 
                            onClick={()=>setShowAdvancedRefund(!showAdvancedRefund)}>
                            {/*Goes back to the detail page when an advanced refund is active */}
                            {showAdvancedRefund ? "Back to Order Details" : "Refund"}
                        </Button> 
                        {refundSuccess && 
                            <div className="py-1 success-text">{refundSuccess}</div>
                        }
                        {/*!showAdvancedRefund &&
                            <Button variant="primary" data-cy="refund-all-btn" disabled={!refundable} onClick={()=>setSimpleRefund(true)}>
                                Refund All
                            </Button>
                        */}
                    </>
                }
                {/*simpleRefund &&
                    <Modal show={simpleRefund} onHide={()=>setSimpleRefund(false)} className={styles.modal} backdropClassName={styles.backdrop}>
                        <Modal.Header closeButton/>
                        <Modal.Body>
                            <SimpleRefund 
                                fullOrder={currentOrder}
                                orderId={currentOrder.id}
                                userId={currentOrder.user_id}
                                user={`${currentOrder.user_fname} ${currentOrder.user_lname}`}
                                registerId={refundRegister} 
                                items={itemsRefundable}
                                onClose={()=>setSimpleRefund(false)}
                                adminFee={currentOrder.admin_fee_total}
                                setError={setError}
                                setSuccess={setSuccess}
                                cleanUp={cleanUp}
                            />
                        </Modal.Body>
                    </Modal>
                */}
                {showAdvancedRefund &&
                    <>
                        <Wizard
                            order={currentOrder}
                            paymentRegister={refundRegister}
                            maxTotal={refundTotal} 
                            setError={setError}
                            setSuccess={setSuccess}
                            cleanUp={cleanUp}
                            onHide={(success)=>{
                                setShowAdvancedRefund(false);
                                if(success) {
                                    redrawOrder();
                                    setSuccess(<Toast>Refund successful!</Toast>);
                                    setRefundSuccess("Refund successful!");
                                }
                            }}
                            redrawOrder={redrawOrder}
                            registerId={registerId}
                        />

                        {/*
                        <Modal show={showAdvancedRefund} size={"xl"} onHide={()=>setShowAdvancedRefund(false)} className={styles.modal} backdropClassName={styles.backdrop}>
                            <Modal.Header closeButton/>
                            <Modal.Body>
                                <Refund 
                                    fullOrder={currentOrder}
                                    paymentRegister={refundRegister}
                                    maxTotal={refundTotal} 
                                    transaction={currentOrder.transactions[0]}
                                    adminFee={currentOrder.admin_fee_total} 
                                    payment_method={currentOrder.payment_method.toLowerCase()}
                                    items={refundItems} 
                                    setError={setError}
                                    setSuccess={setSuccess}
                                    cleanUp={cleanUp}
                                    orderLength={orderLength}
                                    amountRefund={currentOrder.parent_id ? true : false}
                                    transactionId={currentOrder?.transactions[0]?.transaction_type_id !==3 ? currentOrder?.transactions[0]?.id : 0} //if the order has yet to be refunded, there will only be one transaction, but if it's refunded, set to 0 as there is no 0 transaction
                                /> 
                                
                            </Modal.Body>
                        </Modal>
                    */}
                    </>
                }
        </div>
    )
}

export default OrderItems;