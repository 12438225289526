import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { Button, Form } from 'react-bootstrap';
import { useCookies } from 'react-cookie';

import ErrorCatcher from '../../../../components/common/ErrorCatcher'
import Pos from '../../../../api/Pos';
import LocationsAPI from '../../../../api/Locations';
import { setItems as setItemsUtil, separateAddons } from '../../Items/Utils';
import { OrderTicket } from '../OrderTicket';
import QRCodeLabel from '../QRCodeLabel';
import Receipt from '../FullPageReceipt/Receipt';

import './LocationPrinter.scss';
import Kitchen from '../FullPageReceipt/Kitchen';

const style = {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100vh',
    width: '100vw',
    zIndex: '1040',
    background: '#E5E5E5',
    overflow: 'hidden',
    padding: '20px',
};

/*
 *  This component polls the API for special printer queued items and if one is found sends it to the attached printer and marks it as complete.
 */
export const LocationPrinter = (props) => {
    const mountedRef = useRef(false);
    const ref = useRef(); // container of component to print
    const printRef = useRef(); // ReactToPrint object

    // const [items, setItems] = useState();
    // const [order, setOrder] = useState(null);
    const [printData, setPrintData] = useState(null);
    const [printItemsNext, setPrintItemsNext] = useState(null);
    const [printItemsCurrent, setPrintItemsCurrent] = useState(null);
    const [triggerPrintNext, setTriggerPrintNext] = useState(0);
    const [printInProgress, setPrintInProgress] = useState(false);
    const [availablePrinters, setAvailablePrinters] = useState();
    const [selectedPrinter, setSelectedPrinter] = useState(null);
    const [selectedPrinterId, setSelectedPrinterId] = useState(0);
    const [pagePartLocationSelect, setPagePartLocationSelect] = useState();
    const [pagePartPrintedDisplay, setPagePartPrintedDisplay] = useState(null);
    const [error, setError] = useState("");
    const [cookies, setCookie, removeCookie] = useCookies(['assigned-printer']);
    const [isLoading, setIsLoading] = useState(true);

    // load the list of all available printers
    const getPrinters = async () => {
        try {
            let response = await LocationsAPI.printLocation.getLocations({});
            if (response.data && mountedRef.current === true) {
                setAvailablePrinters(response.data);
            };
        } catch(ex) {
            setError(<ErrorCatcher error={ex} />);
        }
    }

    useEffect(() => {
        mountedRef.current = true;

        const timerReload = setTimeout(() => window.location.reload(true), 10*60*1000);

        getPrinters();

        return () => {
            mountedRef.current = false;
            clearInterval(timerReload);
        }
    },[]);

    // first load - read from local storage and cookies
    useEffect(() => {
        if (isLoading) {
            // on first load let's check if we have a cookie or storage value
            console.log(localStorage.getItem('assigned-printer'));
            let storage = JSON.parse(localStorage.getItem('assigned-printer'));
            let cookie = cookies['assigned-printer'];
            // if localStorage is empty check for a cookiej
            let valueToSetPrinterId = storage || cookie || null;
            // error check
            if (isNaN(valueToSetPrinterId)) {
                valueToSetPrinterId = 0;
            }
            setSelectedPrinterId(valueToSetPrinterId);
            
            setIsLoading(false);
        }
    },[cookies, isLoading]);

    // every time selectedPrinterId changes, update the selectedPrinter appropriately
    useEffect(() => {
        if (availablePrinters?.length>0 && !isLoading) {
            console.log("Now set it to: ", selectedPrinterId);
            console.log("selectedPrinterId", selectedPrinterId);
            // find active printer object to make sure it's still available as a printer
            let printer = undefined;
            if (!isNaN(selectedPrinterId)) {
                printer = availablePrinters.find(location => parseInt(location.id)===parseInt(selectedPrinterId));
            }
            console.log("printer", printer);
            setSelectedPrinter(printer);

            // save active printer to local storage and cookies
            if (selectedPrinterId===0) {
                console.log("selectedPrinter has been unset, remove localStorage");
                localStorage.removeItem('assigned-printer');
                removeCookie('assigned-printer');
            } else if (!selectedPrinterId) {
                console.log("selectedPrinter is not set: ", selectedPrinterId);
            } else  {
                localStorage.setItem('assigned-printer', selectedPrinterId);
                setCookie('assigned-printer', selectedPrinterId);
            } 
        }
    },[availablePrinters, setCookie, removeCookie, selectedPrinterId, isLoading]);
    
    useEffect(() => {
        
        const loadTicket = async (id) => {
            // get order details
            await LocationsAPI.printLocation.getNext({
                location_id: id,
            }).then(async response => {
                if (!response.errors) {
                    console.log(response.data?.order)
                    console.log(response.data)
                    console.log(selectedPrinter)
                    if (response.data) {
                        setPrintData(response.data);
                    } else {
                        // nothing to print
                        setPrintInProgress(false);
                    }
                } else {
                    console.error(response.errors);
                    setPrintInProgress(false);
                }
            }).catch(e => console.error(e));
        }

        const interval = setInterval(() => {
            if (!printInProgress && selectedPrinter) {
                // do the api call here
                setPrintInProgress(true);
                loadTicket(selectedPrinter.id);
            }
        }, 5000); // 5 seconds

        return () => {
            clearInterval(interval);
        }
    },[printInProgress, selectedPrinter]);

    useEffect(() => {

        const onSelectPrinter = (e) => {
            setSelectedPrinterId(parseInt(e.target.value));
        }

        if (!availablePrinters && isLoading) {
            setPagePartLocationSelect(
                <p>loading...</p>
            );
        } else {
            setPagePartLocationSelect(
                <>
                    <Form.Group controlId="printer_location" className="select-printer-location">
                        <Form.Label>Select a Printer</Form.Label>
                        <Form.Control
                            required
                            custom
                            style={{ width: "auto" }}
                            as="select"
                            name="location_id"
                            value={selectedPrinterId}
                            onChange={onSelectPrinter}
                        >
                            <option key="default_option" value={0}></option>
                            {availablePrinters?.map( location => 
                                <option key={`option_${location.id}`} value={location.id}>{location.name}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                </>
            );
        }
    },[availablePrinters, selectedPrinterId, isLoading]);

    const onPrintComplete = async (print_item_id) => {
        // mark as complete to the api
        await LocationsAPI.printLocation.complete({
            id: print_item_id
        }).then(async response => {
            if (!response.errors) {
                // nothing to do
            } else {
                console.error(response.errors);
            }
            setPrintData();
            setPrintInProgress(false);
            setPrintItemsCurrent(null);
            setPrintItemsNext(null);
        }).catch(e => console.error(e));
    }

    const getProcessedItems = (printData) => {
        let items_set = setItemsUtil(printData.order.items, null, printData.order.id, printData.order.user_id);
        return separateAddons(items_set);
    }

    useEffect(() => {

        console.log(printData)

        if (selectedPrinter?.params?.printer_type === 'order' && printData?.order) {
            let printItems = [];
            // check for single_item_per_print and separate them out
            if (selectedPrinter?.params?.single_item_per_print) {
                let upcomingItemsList = [];
                if (!printItemsCurrent) {
                    upcomingItemsList = getProcessedItems(printData);
                } else {
                    upcomingItemsList = printItemsCurrent;
                }
                // we have to peel out the next item and print it, saving the remaining in printDataNext
                if (upcomingItemsList.length>0) {
                    // let printItemFirst = upcomingItemsList.shift(); // removes the first item from the array an changes the array itself
                    // now grab the first item and all matching addons out of the remaining list
                    let itemId = upcomingItemsList[0].id;
                    let firstItems = [];
                    let nextItems = [];
                    upcomingItemsList.forEach(item => {
                        if (item.id === itemId || item.parent_id === itemId) {
                            firstItems.push(item);
                        } else {
                            nextItems.push(item);
                        }
                    });
                    printItems = firstItems;
                    setPrintItemsNext(nextItems);
                }
            } else {
                printItems = getProcessedItems(printData);
                setPrintItemsNext(null);
            }
            let customer = {
                first_name: printData.order.user_fname,
                last_name: printData.order.user_lname
            }
            setPagePartPrintedDisplay (
                <div className="receipt-print-t1">
                    <OrderTicket width={selectedPrinter?.params?.width} items={printItems} orderId={printData.order.id} printerName={selectedPrinter.name} customer={customer} />
                </div>
            );
            return;
        } else if (selectedPrinter?.params?.printer_type === 'qr_code' && printData?.print_data) {
            if (printData.print_data?.valueToEncode) {
                setPagePartPrintedDisplay (
                    <QRCodeLabel
                        value={printData.print_data?.valueToEncode || null}
                        bigText={printData.print_data?.displayText || null}
                        width={selectedPrinter?.params?.width}
                    />
                ); 
            } else {
                console.error("There is no valueToEncode when attempting to print the QR Code from print job id: " + printData?.id);
            }
            return;
        } else if(selectedPrinter?.params?.printer_type === "online_order" && printData?.order && printData?.order.register_id === 9){
            //HARDCODE - I have to add the register here because all locations from the backend on orders are coming back as 1.  Gotta fix that in the next iteration
            setPagePartPrintedDisplay (
                <>
                    <Receipt 
                        userDetails={printData?.order?.user}
                        order={printData?.order}
                        items={printData?.order?.items}
                        // transaction={}
                        // paymentMethod={}
                        // company={}
                        // companyLogo={}
                        // parentOrder={}
                    />
                    <div style={{pageBreakBefore: "always"}} />
                    
                    <Kitchen 
                        userDetails={printData?.order?.user}
                        order={printData?.order}
                        items={printData?.order?.items}
                    />
                </>
            )
        }else {
            setPagePartPrintedDisplay(null);
        }
    },[printData, selectedPrinter, printItemsCurrent]);

    useEffect(() => {
        if (pagePartPrintedDisplay!==null && selectedPrinter) {
            printRef.current?.handlePrint();
        }
    },[pagePartPrintedDisplay, selectedPrinter]);

    return (
        <div className="location-printer-container">
            {pagePartLocationSelect}

            {selectedPrinter &&
            <>
                 {pagePartPrintedDisplay ?
                    <>
                        <h4>Printing</h4>
                        <>
                            <ReactToPrint
                                ref={printRef}
                                content={() => ref.current}
                                onAfterPrint={() => {
                                    if (printItemsNext===null || printItemsNext.length===0) {
                                        onPrintComplete(printData?.id)
                                    } else {
                                        setPrintItemsCurrent(printItemsNext);
                                    }
                                }}
                            />
                            <div ref={ref} className="location-printer">
                                {pagePartPrintedDisplay}
                            </div>
                        </>
                    </>
                :
                    <>
                        <h4>Polling for print jobs... Do not close this screen</h4>
                    </>
                }
            </>
            }
        </div>
    ); 
}